import { Box, Typography, Chip, Tooltip, useTheme, Grid, Card } from '@material-ui/core'
import { components, constants, useServices, useTranslation, useWhiteLabelServiceSettings } from 'cng-web-lib'
import moment from 'moment-timezone'
import { useParams } from 'react-router-dom'
import AciHighwayManifestApiUrls from 'src/apiUrls/AciHighwayManifestApiUrls'
import AciHighwayCargoApiUrls from 'src/apiUrls/AciHighwayCargoApiUrls'
import NaCodeMaintenanceApiURLs from 'src/apiUrls/NaCodeMaintenanceApiUrls'
import ManifestKeys from 'src/constants/locale/key/AciHighwayManifest'
import CommonValidationErrorKeys from 'src/constants/locale/key/CommonValidationError'
import React, { useEffect, useRef, useState } from 'react'
import Namespace from 'src/constants/locale/Namespace'
import Table from '../../../components/aciacehighway/Table'
import TinyChip from '../../../components/aciacehighway/TinyChip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import pathMap from 'src/paths/pathMap'
import ConfirmDialog from 'src/views/common/ConfirmDialog'
import NoteFlagDialog from 'src/views/acihighway/manifest/NoteFlagDialog'
import { format } from 'date-fns'
import { getStatusMetadata } from '../../../common/NACommon'
import axios from 'axios'
import { FileForUserGetPartyId, FileForUserGetUserDetails, GetLoggedInUserPartyName } from 'src/common/FileForUserCommon'
import EmailCoverSheetDialog from 'src/views/acihighway/manifest/EmailCoverSheetDialog'
import FaxCoverSheetDialog from 'src/views/acihighway/manifest/FaxCoverSheetDialog'
import TemplateSelectDialogWithOptionACIHWY from 'src/components/aciacehighway/TemplateSelectDialogWithOptionACIHWY'
import CngSection from 'src/components/cngcomponents/CngSection'
import CargoTable from './view/CargoTable'
import sweetalert from 'sweetalert'
import ResponseLog from './view/ResponseLog'
import { fetchUser } from "src/views/userprofile/UserProfileService.js";
import ChargeTypeDialog from '../../common/ChargeTypeDialog'

const {
  button: { CngButton },
  table: { useFetchCodeMaintenanceLookup },
  CngDialog
} = components

const { filter: { BETWEEN, EQUAL, IN, LIKE } } = constants

const dataD4 = {
  "moduleName": "",
  "noticeType": "",
  "cbsaRefNo": "",
  "msgFunctionCode": "",
  "cbsaProcessDateTime": "",
  "noticeReasonCode": "",
  "remarks": "",
  "documentType": "",
  "documentNumber": "",
  "responseId": "",
  "partyId": 0
}

function TablePage(props) {
  const { history, location, manifestId, showNotification } = props
  const { id } = useParams()
  const { state } = location
  const { createRecord, deleteRecord, fetchRecords, securedSendRequest } = useServices()
  const tableRef = useRef(null)
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const whiteLabelServiceSettings = useWhiteLabelServiceSettings("aci-highway")

  const [lookups, setLookups] = useState(null)
  const [manifestTemplateDialog, setManifestTemplateDialog] = useState(false)
  const [confirmDialog, setConfirmDialog] = useState({ open: false, manifest: null })
  const [noteFlagDialog, setNoteFlagDialog] = useState({ open: false, manifest: null })
  const [quickGlanceShipmentDialog, setQuickGlanceShipmentDialog] = useState({ open: false, manifest: null })
  const [quickGlanceResponseLogDialog, setQuickGlanceResponseLogDialog] = useState({ open: false, manifest: null })
  const [emailCoverSheetDialog, setEmailCoverSheetDialog] = useState({ open: false, data: null })
  const [faxCoverSheetDialog, setFaxCoverSheetDialog] = useState({ open: false, data: null })

  const theme = useTheme()
  const [massDeleteConfirmDialog, setMassDeleteConfirmDialog] = useState({ open: false, idList: null })

  const [chargeTypes, setChargeTypes] = useState([])
  const [submitDialog, setSubmitDialog] = useState({ open: false, items: [] })

  const [isSuperParty, setSuperParty] = useState(false);

  const [user, setUser] = useState([]);
  useEffect(() => {
    let fileForUserDetails = FileForUserGetUserDetails();
    fetchUser(setUser, securedSendRequest, fileForUserDetails);
  }, []);

  useEffect(() => {
    Promise.all([
      // Status
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'ACIHWY_STATUS' }],
        undefined,
        'code'
      ),
      // Expected Time Zone
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'SCHEDULER_TIME_ZONE' }],
        undefined,
        'code'
      ),
      // First expected port
      fetchRecords.execute(
        NaCodeMaintenanceApiURLs.GET,
        { 
          filters: [
            { field: 'indicator', operator: EQUAL, value: 'ACI' }
          ],
          customData: { codeMType: 'CustomsOffice' } },
        (res) => {
          const result = {}

          res.content.forEach((item) => {
            result[item.code] = item.descriptionEn
          })

          return result
        }
      ),
      // Charge type
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'BILLING_CHARGE_TYPE' }],
        undefined,
        'code'
      ),
      // Super party
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'BILLING_SUPER_PARTY' }],
        undefined, 'code'
      )
    ]).then(([status, timeZone, firstExpectedPort, billingChargeTypes, billingSuperParty]) => {
      setLookups({ status, timeZone, firstExpectedPort })

      compileChargeTypes(billingChargeTypes)

      Object.keys(billingSuperParty).forEach(e => {
        if (e == GetLoggedInUserPartyName()) {
          setSuperParty(true);
        }
      })
    })
  }, [])

  function compileChargeTypes(billingChargeTypes) {
    var jsonArray = [];
    if (billingChargeTypes) {
      Object.entries(billingChargeTypes).forEach((e) => {
        var jsonObject = { code: e[0], desc: e[1] }
        jsonArray.push(jsonObject)
      })
    }
    setChargeTypes(jsonArray)
  }

  function getDriverName(data) {
    var fullName = '';
    for (let d in data.personDTO) {
      var row = data.personDTO[d]
      if (row.role === 'EJ') {
        fullName = row.firstName + ' ' + row.lastName
      }
    }
    return fullName;
  }

  function getCargoCount(data) {
    var count = 0;
    for (let d in data.cargoDTO) {
      count++;
    }
    return count;
  }

  function getCargoCountStyle(data) {
    var green = true;
    for (let d in data.cargoDTO) {
      var row = data.cargoDTO[d];
      if (!row.entryNo && row.cargoType != "IN-BOND") {
        green = false;
      }
    }

    if (data.status == 1001 || data.status == 1005) {
      return getStatusMetadata(data.status);
    } else if (green) {
      return { color: '#008000' }
    }
    return { color: '#DF2901' }
  }

  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.ACIHWY_MANIFEST,
    Namespace.COMMON_VALIDATION_MESSAGE
  ])

  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let tripNum = translate(Namespace.ACIHWY_MANIFEST, ManifestKeys.TRIP_NUM)
    let scac = translate(Namespace.ACIHWY_MANIFEST, ManifestKeys.SCAC)
    let driver = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.DRIVER
    )
    let truck = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TRUCKTITLE
    )
    let trailer = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.TITLE
    )
    let cargoCount = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.CARGO_COUNT
    )
    let creationdatetime = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.CREATIONDATETIME
    )
    let submittedDate = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.SUBMITTED_DATE
    )
    let expArrivalDate = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.EXP_ARRIVAL_DATE
    )
    let status = translate(Namespace.ACIHWY_MANIFEST, ManifestKeys.STATUS)
    let reviewStatus = translate(Namespace.ACIHWY_MANIFEST, ManifestKeys.REVIEW_STATUS)
    let firstExpectedPort = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.FIRST_EXPECTED_PORT
    )
    let timeZone = translate(Namespace.ACIHWY_MANIFEST, ManifestKeys.TIMEZONE)
    let editSentRecords = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.EDIT_SENT_RECORDS
    )
    let editButton = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.EDIT_BUTTON
    )
    let cloneButton = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.CLONE_BUTTON
    )
    let deleteButton = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.DELETE_BUTTON
    )
    let printCoverSheet = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.PRINT_COVERSHEET
    )
    let d4 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.D4
    )
    let emailCoverSheet = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.EMAIL_COVERSHEET
    )
    let cancelButton = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.CANCEL_BUTTON
    )
    let cancelMultipleRecords = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.CANCEL_MULTIPLE_RECORDS
    )
    let saveButton = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.SAVE_BUTTON
    )
    let noteFlag = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.NOTE_FLAG
    )
    let noteContent = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.NOTE_CONTENT
    )
    let faxCoverSheet = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.FAX_COVERSHEET
    )
    let ccn = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.CCN
    )
    let totalNoOfCargo = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TOTAL_NO_OF_CARGO
    )
    let cargo = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.CARGO
    )
    let quickGlanceTitle = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.QUICK_GLANCE_TITLE
    )
    let okayButton = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.OKAY_BUTTON
    )
    let errorOccured = translate(
      Namespace.COMMON_VALIDATION_MESSAGE,
      CommonValidationErrorKeys.ERROR_CONTACT_CUSTOMER_SERVICE
    )
    return {
      tripNum,
      scac,
      driver,
      truck,
      trailer,
      cargoCount,
      creationdatetime,
      submittedDate,
      expArrivalDate,
      status,
      reviewStatus,
      firstExpectedPort,
      timeZone,
      editSentRecords,
      editButton,
      cloneButton,
      deleteButton,
      printCoverSheet,
      d4,
      emailCoverSheet,
      cancelButton,
      cancelMultipleRecords,
      saveButton,
      noteFlag,
      noteContent,
      faxCoverSheet,
      ccn,
      totalNoOfCargo,
      cargo,
      quickGlanceTitle,
      okayButton,
      errorOccured
    }
  }

  function getLookupValue(name, value) {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  let columns = [
    {
      field: 'noteContentExist',
      title: translatedTextsObject.noteFlag,
      render: (data) => {
        let content = [
          <Tooltip key='more' title={data.reminderNote} placement='top'>
            <Chip
              clickable
              onClick={(event) => {
                event.stopPropagation()
                setNoteFlagDialog({ open: true, manifest: data })
              }}
              icon={<FontAwesomeIcon fixedWidth icon={['fas', 'flag']} style={data.noteContentExist ? { color: 'red' } : null} />}
              size='small'
              style={{ fontSize: 12, backgroundColor: 'transparent' }}
            />
          </Tooltip>
        ]

        return (
          <Box display='flex' flexDirection='row' flexWrap='wrap' style={{ gap: 4 }}>{content}</Box>
        )
      }
    },
    {
      field: 'tripNum',
      sortKey: 'tripNum',
      title: `${translatedTextsObject.tripNum} / ${translatedTextsObject.scac}`,
      render: (data) => (
        <Box>
          <Typography component='div' variant='inherit' style={{ marginBottom: 4 }}>
            {data.tripNum}
          </Typography>
          <Typography color='textSecondary' style={{ fontSize: 12, lineHeight: 1.2 }}>
            {data.carrierCode}
          </Typography>
        </Box>
      )
    },
    {
      field: 'expArrivalDate',
      sortKey: 'expArrivalDate',
      title: translatedTextsObject.expArrivalDate,
      render: (data) =>
        data.expArrivalDate && moment(data.expArrivalDate).format('D MMM YYYY, HH:mm')
    },
    {
      field: 'status',
      sortKey: 'status',
      title: translatedTextsObject.status,
      render: (data) => {
        const status = getStatusMetadata(data.status)

        return (
          <>
            <Tooltip key='more' title='View response(s)' placement='top'>
              <Chip
                label={status.label}
                size='small'
                style={{
                  backgroundColor: status.color,
                  color: status.contrastColor
                }}
                clickable
                onClick={(event) => {
                  event.stopPropagation()
                  setQuickGlanceResponseLogDialog({ open: true, manifest: data })                
                }}
              />
            </Tooltip>
            <Typography color='textSecondary' style={{ fontSize: 12, lineHeight: 1.2 }}>
              {data.responseDateTime && moment(data.responseDateTime).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')}
            </Typography>
          </>
        )
      }  
    },
    {
      title: `Cargo status & ${translatedTextsObject.ccn}`,
      field: 'firstCargoInfo',
      render: (data) => {
        let firstCargoInfo = data.firstCargoInfo

        if (!firstCargoInfo) return

        let firstCargoInfoSplit = firstCargoInfo.split("|")
        let firstCargoInfoStatus = firstCargoInfoSplit[0]
        let firstCargoInfoSCN = firstCargoInfoSplit[1]
        let statusIndicator = {
          backgroundColor: "#008000",
          color: "#ffffff"
        };

        if (data.status == '1001' || data.portMismatch > 0 || data.rejectedCargo > 0) {
          statusIndicator.backgroundColor = "#DF2901";
        } else if (data.awaitingEntryNo > 0) {
          statusIndicator.backgroundColor = "#ffc400";
          statusIndicator.color = "#121212"
        }

        const status = getStatusMetadata(firstCargoInfoStatus)
        if (status.label == 'Accepted') {
          status.color = statusIndicator.backgroundColor;
          status.contrastColor = statusIndicator.color;
        }

        let content = [
          <Chip
            key='first'
            label={[status.label, firstCargoInfoSCN].join(' | ')}
            size='small'
            style={{
              color: status.contrastColor,
              backgroundColor: status.color,
              fontSize: 12
            }}
          />
        ]

        if (data.portMismatch && data.portMismatch > 0) {
          content.push(
            <Chip key='first' label={"Port Mismatch"} size='small'
              style={{
                color: '#ffffff',
                backgroundColor: '#DF2901',
                fontSize: 12
              }}
            />
          )
        }

        function renderButton() {
          const otherCargoInfo = data.noOfCargo - 1;

          return (
            <Tooltip key='more' title='Quick Glance at Cargo' placement='top'>
              <Chip
                label={otherCargoInfo > 0 ? `${otherCargoInfo} more` : 'view'}
                clickable
                onClick={(event) => {
                  event.stopPropagation()
                  setQuickGlanceShipmentDialog({ open: true, manifest: data })
                }}
                icon={<FontAwesomeIcon fixedWidth icon={['fal', 'eye']} />}
                size='small'
                style={{ fontSize: 12 }}
              />
            </Tooltip>
          )
        }

        return (
          <>
            <Box display='flex' flexDirection='row' flexWrap='wrap' style={{ gap: 4 }}>
              {content}
            </Box>
            {(data.noOfCargo && data.noOfCargo > 0) && (
              <Box mt={0.5}>
                {renderButton()}
              </Box>
            )}
          </>
        )
      }
    },
    {
      field: "fullName",
      sortKey: 'fullName',
      title: translatedTextsObject.driver,
      //render: (data) => getDriverName(data)
    },
    {
      field: "conveyanceNum",
      sortKey: 'conveyanceNum',
      title: translatedTextsObject.truck,
    },
    {
      field: "equipmentNo",
      sortKey: 'equipmentNo',
      title: translatedTextsObject.trailer,
      //render: (data) => (undefined !== data.trailerDTO[0] ? data.trailerDTO[0].equipmentNumber : '')
    },
    // {
    //   field: "cargoCount",
    //   title: translatedTextsObject.cargoCount,
    //   render: (data) => {
    //     const style = getCargoCountStyle(data);

    //     return (
    //       <Typography variant='inherit' style={{ color: style.color }}>
    //         {getCargoCount(data)}
    //       </Typography>
    //     )
    //   }
    // },
    {
      field: 'firstExpectedPort',
      sortKey: 'firstExpectedPort',
      title: translatedTextsObject.firstExpectedPort,
      render: (data) =>
        <Typography component='div' variant='inherit'>
          {getLookupValue('firstExpectedPort', data.firstExpectedPort)}
          <TinyChip label={data.firstExpectedPort} variant='outlined' />
        </Typography>
    },
    {
      field: 'createdDate',
      sortKey: 'createdDate',
      title: translatedTextsObject.creationdatetime,
      render: (data) =>
        data.createdDate && moment(data.createdDate).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')
    },
    {
      field: 'submittedDate',
      sortKey: 'submittedDate',
      title: translatedTextsObject.submittedDate,
      render: (data) => data.submittedDate && moment(data.submittedDate).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')
    }
  ]

  let challengerColumns = [
    {
      field: 'apiAutoSubmit',
      sortKey: 'reviewStatus',
      title: translatedTextsObject.reviewStatus,
      render: (data) => {
        if(data.apiAutoSubmit){
          const reviewStatus = getStatusMetadata(data.apiAutoSubmit)

          return (
            <>
              <Chip
                label={reviewStatus.label}
                size='small'
                style={{
                  backgroundColor: reviewStatus.color,
                  color: reviewStatus.contrastColor
                }}
              />
            </>
          )
        }
      }
    }
  ]

  if (whiteLabelServiceSettings && whiteLabelServiceSettings.length > 0
    && whiteLabelServiceSettings[0].custom.UI_MANIFEST_CARGO_TABLE_REVIEW_STATUS === 'true') {
    columns = [...columns, ...challengerColumns]
  }

  const filters = [
    {
      label: translatedTextsObject.tripNum,
      type: 'textfield',
      name: 'tripNum',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.expArrivalDate,
      type: 'daterange',
      name: 'expArrivalDate',
      operatorType: BETWEEN
    },
    {
      label: 'Follow-up Status',
      type: 'radio',
      name: 'firstCargoInfo',
      operatorType: EQUAL,
      options: [
        { label: 'Pending Entry Number', value: 'yellow', filterValue: { value: 'yellow' } },
        { label: 'Port Mismatch', value: 'red', filterValue: { value: 'red' } }
      ]
    },
    {
      label: translatedTextsObject.driver,
      type: 'textfield',
      name: 'fullName',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.truck,
      type: 'textfield',
      name: 'conveyanceNum',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.trailer,
      type: 'textfield',
      name: 'equipmentNo',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.firstExpectedPort,
      type: 'textfield',
      name: 'firstExpectedPort',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.creationdatetime,
      type: 'daterange',
      name: 'createdDate',
      operatorType: BETWEEN
    },
    {
      label: 'Last Modified Date',
      type: 'daterange',
      name: 'submittedDate',
      operatorType: BETWEEN
    },
    {
      label: translatedTextsObject.status,
      type: 'checkbox',
      name: 'status',
      operatorType: IN,
      options: [
        {
          label: 'Draft',
          value: 'draft',
          filterValue: {
            value: '1005'
          }
        },
        {
          label: 'Accepted',
          value: 'accepted',
          filterValue: {
            value: '1000'
          }
        },
        {
          label: 'Sent',
          value: 'sent',
          filterValue: {
            value: '1008'
          }
        },
        {
          label: 'Rejected',
          value: 'rejected',
          filterValue: {
            value: '1001'
          }
        },
        /*{
          label: 'Template',
          value: 'Template',
          filterValue: {
            value: '1002'
          }
        },*/
        {
          label: 'Status Notification',
          value: 'status_notification',
          filterValue: {
            value: '1006'
          }
        },
        {
          label: 'Cancellation Accepted',
          value: 'cancellation_accepted',
          filterValue: {
            value: '1003'
          }
        },
        {
          label: 'Cancellation Rejected',
          value: 'cancellation_rejected',
          filterValue: {
            value: '1004'
          }
        },
        {
          label: 'Modification Rejected',
          value: 'modification_rejected',
          filterValue: {
            value: '1007'
          }
        },
        {
          label: 'Truck Arrived',
          value: 'truck_arrived',
          filterValue: {
            value: '1024'
          }
        },
        {
          label: 'Goods Released',
          value: 'goods_released',
          filterValue: {
            value: '1025'
          }
        }
      ]
    },
    {
      label: translatedTextsObject.reviewStatus,
      type: 'checkbox',
      name: 'apiAutoSubmit',
      operatorType: IN,
      options: [
        {
          fullWidth: true,
          label: 'Pending Review',
          value: 'pending_review',
          filterValue: {
            value: 'R'
          }
        },
        {
          fullWidth: true,
          label: 'Review in Progress',
          value: 'review_in_progress',
          filterValue: {
            value: 'Y'
          }
        },
        {
          fullWidth: true,
          label: 'Reviewed',
          value: 'reviewed',
          filterValue: {
            value: 'G'
          }
        }
      ]
    }
  ]

  function handleCloneManifest(data) {
    data.cloneFrom = data.tripNum;
    createRecord.execute(
      AciHighwayManifestApiUrls.CLONE,
      data,
      (data) => {
        const trailerData = data.trailerDTO.map(
          (trailerDTO, index) => ({ ...trailerDTO, _id: index + 1 })
        )
        data.trailerDTO = trailerData

        const personData = data.personDTO.map(
          (personDTO, index) => ({ ...personDTO, _id: index + 1 })
        )
        data.personDTO = personData
        data.expArrivalTime = moment(data.expArrivalDate).format('HH:mm');
        data.expArrivalDate = moment(data.expArrivalDate).format('YYYY-MM-DD');

        history.push(pathMap.ACIHWY_MANIFEST_ADD_VIEW, data)
      },
      (error) => {
        console.log(error)
      }
    )
  }

  function handleDeleteManifest() {
    if (confirmDialog.manifest) {
      deleteRecord.execute(
        AciHighwayManifestApiUrls.DELETE,
        confirmDialog.manifest,
        () => {
          showNotification('success', 'Delete succeeded')
          setConfirmDialog({ open: false, manifest: null })
          setManifestTemplateDialog(false)
          tableRef.current.performRefresh()
        },
        (error) => {
          console.log(error)
        }
      )
    }
  }

  function preCancel(data) {
    if (isSuperParty || (null != user.corpid && undefined != user.corpid && user.corpid.length > 0 && user.corpid[0].serviceBureauFlag)) {
      setSubmitDialog({ open: true, items: data })
    } else {
      data.chargeAs = 'REGULAR'
      handleCancelManifest(data)
    }
  }

  async function handleCancelManifest(data) {
    let chargeTypes = data.chargeAs
    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      data.fileForUserId = fileForUserDetails.fileForUserId;
      data.fileForUserLoginId = fileForUserDetails.fileForUserLoginId;
      data.fileForUserPartyId = fileForUserDetails.fileForUserPartyId;
    }

    tableRef.current.setLoading(true)
    const instance = axios.create({
      withCredentials: false,
      headers: {
        Authorization:
          'Bearer ' + JSON.parse(localStorage.getItem('userToken')),
        'Content-Type': 'application/json'
      }
    })

    const response = await fetchRecords.execute(
      AciHighwayCargoApiUrls.GET,
      {
        filters: [{
          field: 'manifestId',
          operator: EQUAL,
          value: data.id
        }, {
          field: 'status',
          operator: IN,
          value: ['1000', '1007', '1004']
        }]
      },
      (data) => data.content)
    const cargos = [...response]

    if (cargos.length > 0) {
      console.log("cargos :", cargos)
      cargos.forEach(cargo => {
        if (fileForUserDetails != null && fileForUserDetails != undefined) {
          cargo.fileForUserId = fileForUserDetails.fileForUserId;
          cargo.fileForUserLoginId = fileForUserDetails.fileForUserLoginId;
          cargo.fileForUserPartyId = fileForUserDetails.fileForUserPartyId;
        }
        cargo.messageFunction = '03'
        cargo.deleteFromManifest = 'Y'
        cargo.chargeAs = chargeTypes
      })

      instance
        .post(AciHighwayManifestApiUrls.SUBMIT_ALL, JSON.stringify(cargos))
        .then(
          (result) => {
            var returnData = result.data
            var errorMessages = returnData.errorMessages
            if (
              result.status == 200 &&
              returnData != null &&
              errorMessages == null
            ) {
              showNotification('success', 'Manifest cancelled. Cargo(s) will be sent for cancellation within 2 minutes.')
              tableRef.current.performRefresh();
            } else {
              showNotification('error', errorMessages)
            }
          },
          (error) => console.log(error),
          () => tableRef.current.setLoading(false)
        )
    } else {
      console.log("empty cargos :", cargos)
      data.messageFunction = '03'
      instance
        .post(AciHighwayManifestApiUrls.SUBMIT, JSON.stringify(data))
        .then(
          (result) => {
            var returnData = result.data
            var errorMessages = returnData.errorMessages
            console.log("result.status: " + result.status);
            console.log("returnData: " + returnData);
            console.log("errorMessages: " + errorMessages);
            if (
              result.status == 200 &&
              returnData != null &&
              errorMessages == null
            ) {
              showNotification('success', 'Manifest Cancel Send Successfully.')
              tableRef.current.performRefresh();
            } else {
              showNotification('error', errorMessages)
            }
          },
          (error) => { console.log("error: " + JSON.stringify(error)); showNotification('error', translatedTextsObject.errorOccured); tableRef.current.setLoading(false) },
          () => tableRef.current.setLoading(false)
        )
    }

  }

  function handlePrintCoverSheet(data) {
    const config = { responseType: 'blob' }

    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      data.fileForUserId = fileForUserDetails.fileForUserId;
      data.fileForUserLoginId = fileForUserDetails.fileForUserLoginId;
      data.fileForUserPartyId = fileForUserDetails.fileForUserPartyId;
    }

    securedSendRequest.execute(
      'POST',
      AciHighwayManifestApiUrls.PRINTCOVERSHEET,
      data,
      (response) => {
        let blob = new Blob([response.data])

        let first = 'ACI_HW_COVER'
        let curDate = format(new Date(), 'yyyyMMddHHmmss')
        let filename = first + curDate + '.pdf'
        let url = window.URL.createObjectURL(blob)
        let a = document.createElement('a')
        a.href = url
        a.download = filename
        a.click()
      },
      (error) => console.error(error),
      undefined,
      config,
      null
    )
  }

  function handlePrintD4(dataD4) {
    console.log("Inside d4 print " + dataD4);
    const config = { responseType: 'blob' }

    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      dataD4.fileForUserId = fileForUserDetails.fileForUserId;
      dataD4.fileForUserLoginId = fileForUserDetails.fileForUserLoginId;
      dataD4.fileForUserPartyId = fileForUserDetails.fileForUserPartyId;
    }

    securedSendRequest.execute(
      'POST',
      AciHighwayManifestApiUrls.PRINTD4,
      dataD4,
      (response) => {
        let blob = new Blob([response.dataD4])

        let first = 'ACI_HW_D4_'
        let curDate = format(new Date(), 'yyyyMMddHHmmss')
        let filename = first + curDate + '.pdf'
        let url = window.URL.createObjectURL(blob)
        let a = document.createElement('a')
        a.href = url
        a.download = filename
        a.click()
      },
      (error) => console.error(error),
      undefined,
      config,
      null
    )
  }

  async function exportManifest(data) {
    if (data.length > 0) {
      const config = { responseType: 'blob' }
      console.log(data);

      securedSendRequest.execute('POST', AciHighwayManifestApiUrls.EXPORT_MANIFEST, data,
        (response) => {
          let blob = new Blob([response.data])

          let first = 'ACIHW_MANIFEST_EXPORT_'
          let curDate = format(new Date(), 'yyyyMMddHHmmss')
          let filename = first + curDate + '.csv'
          let url = window.URL.createObjectURL(blob)
          let a = document.createElement('a')
          a.href = url
          a.download = filename
          a.click()
        },
        (error) => console.error(error),
        undefined,
        config,
        null
      )
    }
  }

  function handleMassDeleteManifest() {
    if (massDeleteConfirmDialog.idList) {
      setMassDeleteConfirmDialog({ open: false })
      sweetalert("Manifest deletion in progress...", "", "success");
      securedSendRequest.execute('POST',
        AciHighwayManifestApiUrls.MASS_DELETE,
        massDeleteConfirmDialog.idList,
        () => {
          showNotification('success', 'Mass delete succeeded')
          setMassDeleteConfirmDialog({ open: false, idList: null })
          tableRef.current.performRefresh()
        },
        (error) => {
          showNotification('error', 'Mass Delete - ' + translatedTextsObject.errorOccured)
          console.log(error)
        }
      )
    }
  }

  return (
    <>
      <Table
        actions={[
          {
            buttonProps: {
              color: 'primary',
              size: 'medium',
              startIcon: <FontAwesomeIcon icon={['fal', 'plus-circle']} />,
              onClick: () => history.push(pathMap.ACIHWY_MANIFEST_ADD_VIEW)
            },
            label: 'Create manifest'
          },
          {
            buttonProps: {
              color: 'primary',
              size: 'medium',
              startIcon: <FontAwesomeIcon icon={['fal', 'plus-circle']} />,
              onClick: () => setManifestTemplateDialog(true)
            },
            label: 'Manifest Template'
          }
        ]}
        columns={columns}
        compact
        exportData={{ url: AciHighwayManifestApiUrls.CUSTOM_EXPORT }}
        fetch={{ url: AciHighwayManifestApiUrls.CUSTOM_SEARCH }}
        fetchFilters={[          
          { field: 'templateFlag', operator: EQUAL, value: '0' },
          { field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() }
        ]}
        filters={filters}
        onRowClick={(rowData) => {
          if (rowData.status === '1008') {
            history.push(`${location.pathname}/view/${rowData.id}`)
          }
          else {
            history.push(`${location.pathname}/edit/${rowData.id}`)
          }
        }}
        rowActions={[
          {
            disabled: (rowData) => rowData.status === '1008',
            icon: <FontAwesomeIcon icon={['fal', 'pen']} />,
            label: translatedTextsObject.editButton,
            onClick: (rowData) =>
              history.push(`${location.pathname}/edit/${rowData.id}`),
            tooltip: (rowData) =>
              rowData.status === '1008'
                ? translatedTextsObject.editSentRecords
                : null
          },
          {
            icon: <FontAwesomeIcon icon={['fal', 'copy']} />,
            label: translatedTextsObject.cloneButton,
            onClick: handleCloneManifest
          },
          {
            disabled: (data) => {
              return data.status !== '1000'
            },
            label: translatedTextsObject.cancelButton,
            icon: <FontAwesomeIcon icon={['fal', 'ban']} />,
            onClick: preCancel,
            tooltip: (rowData) =>
              rowData.status !== '1000'
                ? translatedTextsObject.cancelMultipleRecords
                : null
          },
          {
            disabled: (data) => data.status !== '1005',
            label: translatedTextsObject.deleteButton,
            icon: <FontAwesomeIcon icon={['fal', 'trash']} />,
            onClick: (data) => setConfirmDialog({ open: true, manifest: data })
          },
          {
            label: translatedTextsObject.printCoverSheet,
            icon: <FontAwesomeIcon icon={['fal', 'print']} />,
            onClick: handlePrintCoverSheet
          },
          // {
          //   label: translatedTextsObject.d4,
          //   icon: <FontAwesomeIcon icon={['fal', 'print']} />,
          //   onClick: handlePrintD4
          // },
          {
            icon: <FontAwesomeIcon icon={['fal', 'envelope']} />,
            label: translatedTextsObject.emailCoverSheet,
            onClick: (rowData) => setEmailCoverSheetDialog({ open: true, data: rowData }),
          },
          {
            icon: <FontAwesomeIcon icon={['fal', 'fax']} />,
            label: translatedTextsObject.faxCoverSheet,
            onClick: (rowData) => setFaxCoverSheetDialog({ open: true, data: rowData }),
          }
        ]}
        showNotification={showNotification}
        sortConfig={{
          type: 'column',
          defaultField: 'createdDate',
          defaultDirection: 'DESC'
        }}
        persistSettings
        tableRef={tableRef}
        checkboxSelection
        tablePreference={{ module: 'ACIHWY', key: 'manifest_table_visible_column' }}
        selectActions={[
          {
            // disabled: (rows) => rows.length > 100,
            icon: <FontAwesomeIcon icon={['fal', 'link']} />,
            label: "Export Manifest(s)",
            onClick: (rows) => {
              if (rows.length > 100) {
                sweetalert("Maximum of 100 records per export.", "", "error");
              } else {
                sweetalert("Exporting Manifest in the background. The report will be downloaded automatically once ready.", "", "success");
                return exportManifest(rows);
              }
            },
            tooltip: (rows) => rows.length > 100 ? "Maximum of 100 records per export" : null

          },
          {
            disabled: (rows) => {
              let disable = false;
              rows.forEach(e => {
                if (e.status !== '1005') { disable = true }
              })
              return disable;
            },
            icon: <FontAwesomeIcon icon={['fal', 'trash']} />,
            label: "Mass Delete",
            tooltip: (rows) => {
              let description = null;
              rows.forEach(e => {
                if (e.status !== '1005') { description = 'Only draft status can be deleted' }
              })
              return description;
            },
            buttonProps: {
              size: 'medium',
              color: 'secondary',
              style: { color: theme.palette.error.main, minWidth: 'auto' }
            },
            onClick: (rows) => {
              let idList = rows.map(e => e.id)
              setMassDeleteConfirmDialog({ open: true, idList: idList });
            }
          }
        ]}
      />
      <QuickGlanceShipmentDialog
        getLookupValue={getLookupValue}
        manifest={quickGlanceShipmentDialog.manifest}
        onClose={() => setQuickGlanceShipmentDialog({ open: false, manifest: null })}
        open={quickGlanceShipmentDialog.open}
        translatedTextsObject={translatedTextsObject}
      />

      <QuickGlanceResponseLogDialog
        getLookupValue={getLookupValue}
        manifest={quickGlanceResponseLogDialog.manifest}
        onClose={() => setQuickGlanceResponseLogDialog({ open: false, manifest: null })}
        open={quickGlanceResponseLogDialog.open}
        translatedTextsObject={translatedTextsObject}
      />
       <TemplateSelectDialogWithOptionACIHWY
        manifestTemplateDialog={manifestTemplateDialog}
        setManifestTemplateDialog={setManifestTemplateDialog}
        setConfirmDialog={setConfirmDialog}
        showNotification={showNotification}
        translatedTextsObject={translatedTextsObject}
        isTable={true}
        location={location}
        rowActions={[
          {
            icon: <FontAwesomeIcon icon={['fal', 'pen']} />,
            label: 'Edit',
            onClick: (rowData) =>
              history.push(pathMap.ACIHWY_MANIFEST+`/edit/${rowData.id}`),
            tooltip: () => 'edit'
          },
          {
            icon: <FontAwesomeIcon icon={['fal', 'trash']} />,
            label: 'Delete',
            onClick: (rowData) =>
              setConfirmDialog({ open: true, manifest: rowData }),
            tooltip: () => 'delete'
          }
        ]}
      />
      <ConfirmDialog
        isConfirmDialogOpen={confirmDialog.open}
        closeDialog={() => setConfirmDialog({ open: false, manifest: null })}
        confirmDialog={handleDeleteManifest}
        content="Items that you delete can't be restored. Are you sure about this?"
        okMsg='Yes, delete'
        cancelMsg='No, take me back'
        title={translatedTextsObject.deleteButton}
      />
      <NoteFlagDialog
        openDialog={noteFlagDialog}
        manifestId={manifestId}
        onClose={() => setNoteFlagDialog({ open: false, manifest: null })}
        showNotification={showNotification}
        tableRef={tableRef}
      />
      <EmailCoverSheetDialog
        openDialog={emailCoverSheetDialog}
        onClose={() => setEmailCoverSheetDialog({ open: false, data: null })}
        showNotification={showNotification}
      />
      <FaxCoverSheetDialog
        openDialog={faxCoverSheetDialog}
        onClose={() => setFaxCoverSheetDialog({ open: false, data: null })}
        showNotification={showNotification}
      />
      <ChargeTypeDialog
        chargeTypes={chargeTypes}
        onClose={() => setSubmitDialog({ open: false, items: [] })}
        onSubmit={(data) => {
          handleCancelManifest(data)
        }}
        open={submitDialog.open}
        items={submitDialog.items}
      />
      <ConfirmDialog
        isConfirmDialogOpen={massDeleteConfirmDialog.open}
        closeDialog={() => setMassDeleteConfirmDialog({ open: false, idList: null })}
        confirmDialog={handleMassDeleteManifest}
        content="Items that you delete can't be restored. Are you sure about this?"
        okMsg='Yes, delete'
        cancelMsg='No, take me back'
        title={translatedTextsObject.deleteButton}
      />
    </>
  )
}

export default TablePage

function QuickGlanceShipmentDialog(props) {

  const { getLookupValue, manifest, onClose, open, translatedTextsObject } = props
  const { securedSendRequest } = useServices()
  const theme = useTheme()
  const [statusList, setStatusList] = useState([])
  const [redirectUrl, setRedirectUrl] = useState()

  const redirectData = {
    manifestData: { ...manifest },
    step: "2"
  }

  useEffect(() => {

    //reset status
    setStatusList([])
    if (manifest != null && manifest != undefined && open) {
      if (manifest.status != null && manifest.status != undefined && manifest.status == "1008") {
        setRedirectUrl("/aci-highway/manifest/view/" + manifest.id)
      } else {
        setRedirectUrl("/aci-highway/manifest/edit/" + manifest.id)
      }


      const formData = new FormData();
      formData.append('manifestId', manifest.id);

      let config = {}

      securedSendRequest.execute(
        'POST',
        AciHighwayCargoApiUrls.GET_COUNT_BY_STATUS,
        formData,
        (response) => {
          console.log(response)
          let data = response.data
          if (data != null && data != undefined && data.length > 0) {
            var result = data.reduce(function (map, obj) {
              map[obj.STATUS] = obj.TOTAL;
              return map;
            }, {});
            setStatusList(data)
          }
        },
        (error) => console.error(error),
        undefined,
        config,
        null
      )
    }
  }, [open]);

  let content = manifest ? (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card variant='outlined'>
          <Box px={2} py={1}>
            <Grid container justify='space-between' spacing={2}>
              <Grid item xs={12} sm='auto'>
                <Typography variant='h6' style={{ fontWeight: 700 }}>
                  {translatedTextsObject.totalNoOfCargo}
                </Typography>
              </Grid>
              <Grid item xs={12} sm='auto'>
                <Typography variant='body2'>
                  <Typography
                    color='textSecondary'
                    component='span'
                    variant='inherit'
                    style={{ fontWeight: 700 }}
                  >
                    {translatedTextsObject.scac} &amp; {translatedTextsObject.tripNum}:&nbsp;
                  </Typography>
                  <Typography component='span' variant='inherit' style={{ fontWeight: 700 }}>
                    {manifest.tripNum}
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box px={2} py={1} style={{ backgroundColor: theme.palette.background.sectionOddBg }}>
            <Grid container flexWrap='wrap' spacing={2}>
              {statusList.map((data) => {
                const statusData = getStatusMetadata(data.STATUS)
                return (
                  <Grid item xs='auto'>
                    <Typography variant='body2' style={{ fontWeight: 700 }}>
                      {statusData.label}&nbsp;
                      <Typography
                        color='primary'
                        component='span'
                        variant='inherit'
                      >
                        {data.TOTAL}
                      </Typography>
                    </Typography>
                  </Grid>
                )
              })}
            </Grid>
          </Box>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <CngSection
          title={
            <Box display='flex' alignItems='baseline' style={{ gap: 4 }}>
              <Typography style={{ fontWeight: 600 }}>
                {translatedTextsObject.cargo}
              </Typography>
              <Typography color='textSecondary' variant='caption'>
                {/*`/ ${manifest.shipmentInfo.length} Entries`*/}
              </Typography>
            </Box>
          }
        >
          <CargoTable
            disableView
            getLookupValue={getLookupValue}
            manifestId={manifest.id}
            manifestPort={manifest.firstExpectedPort}
            noOfCargo={manifest.noOfCargo}
            redirectUrl={redirectUrl}
            redirectData={redirectData}
            additionalButtonLabel={translatedTextsObject.additionalCargo}
          />
        </CngSection>
      </Grid>
    </Grid>
  ) : null

  return (
    <CngDialog
      dialogContent={content}
      dialogAction={<CngButton onClick={onClose}>{translatedTextsObject.okayButton}</CngButton>}
      dialogTitle={translatedTextsObject.quickGlanceTitle}
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      fullWidth
      maxWidth='lg'
      onClose={onClose}
      open={open}
      shouldShowCloseButton
    />
  )
}

function QuickGlanceResponseLogDialog(props) {
  const { getLookupValue, manifest, onClose, open, translatedTextsObject } = props
    const { securedSendRequest } = useServices()
    const theme = useTheme()
    const [statusList, setStatusList] = useState([])
    const [redirectUrl, setRedirectUrl] = useState()
  
    const redirectData = {
      manifestData: {...manifest},
      step: "2"
    }
  
  let content = manifest ? (
      <Grid item xs={12}>
            <ResponseLog
              disableView
              getLookupValue={getLookupValue}
              manifestId={manifest.id}
              manifestPort={manifest.firstExpectedPort}
             noOfShipment={manifest.noOfShipment}
             redirectUrl={redirectUrl}
             redirectData={redirectData}
             additionalButtonLabel={translatedTextsObject.additionalShipment}
            />  
        </Grid>
    ) : null
      return (
      <CngDialog
        dialogContent={content}
        dialogAction={<CngButton onClick={onClose}>OK</CngButton>}
        dialogTitle='Response log '
        fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
        fullWidth
        maxWidth='lg'
        onClose={onClose}
        open={open}
        shouldShowCloseButton
      />
    )
}