import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
  constants,
  useTranslation
} from 'cng-web-lib'

import ArrivalCertResponseApiUrls from 'src/apiUrls/ArrivalCertResponseApiUrls'
import AciHighwayTransactionApiUrls from 'src/apiUrls/AciHighwayTransactionApiUrls'
import React from 'react'
import ArrivalCertResponseKeys from 'src/constants/locale/key/ArrivalCertResponse'
import Namespace from 'src/constants/locale/Namespace'
import { StatusStyle, DisableDeleteButtonForHighway } from 'src/common/NACommon'

const {
  card: { CngSimpleCardHeader },
  table: {
    CngCrudTable,
    DateRangeFilter: CngDateRangeFilter,
    useDefaultNotification,
    useFetchCodeMaintenanceLookup,
  }
} = components

const {
  locale: {
    key: { UiComponentKeys }
  },
  filter: { EQUAL }
} = constants

function ArrivalCertResponse(props) {
  const {
    showNotification,
    id
  } = props

  const notification = useDefaultNotification(showNotification)
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.ARRIVAL_CERT_RESPONSE
  ])
  const translatedTextsObject = makeTranslatedTextsObject()
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()

  function makeTranslatedTextsObject() {
    let arrivalCertResponse = translate(
      Namespace.ARRIVAL_CERT_RESPONSE,
      ArrivalCertResponseKeys.TITLE
    )
    let tableTitle = translate(
      Namespace.UI_COMPONENT,
      UiComponentKeys.Table.TITLE,
      {
        nameTitleised: arrivalCertResponse
      }
    )
    let id = translate(
      Namespace.ARRIVAL_CERT_RESPONSE,
      ArrivalCertResponseKeys.ID
    )
    let arrivalCertResponseId = translate(
      Namespace.ARRIVAL_CERT_RESPONSE,
      ArrivalCertResponseKeys.ARRIVAL_CERT_RESPONSE_ID
    )
    let status = translate(
      Namespace.ARRIVAL_CERT_RESPONSE,
      ArrivalCertResponseKeys.STATUS
    )
    let remarks = translate(
      Namespace.ARRIVAL_CERT_RESPONSE,
      ArrivalCertResponseKeys.REMARKS
    )
    let responsePath = translate(
      Namespace.ARRIVAL_CERT_RESPONSE,
      ArrivalCertResponseKeys.RESPONSE_PATH
    )
    let ccn = translate(
      Namespace.ARRIVAL_CERT_RESPONSE,
      ArrivalCertResponseKeys.CCN
    )
    let createdBy = translate(
      Namespace.ARRIVAL_CERT_RESPONSE,
      ArrivalCertResponseKeys.CREATED_BY
    )
    let createdDate = translate(
      Namespace.ARRIVAL_CERT_RESPONSE,
      ArrivalCertResponseKeys.CREATED_DATE
    )
    let updatedBy = translate(
      Namespace.ARRIVAL_CERT_RESPONSE,
      ArrivalCertResponseKeys.UPDATED_BY
    )
    let updatedDate = translate(
      Namespace.ARRIVAL_CERT_RESPONSE,
      ArrivalCertResponseKeys.UPDATED_DATE
    )
    let version = translate(
      Namespace.ARRIVAL_CERT_RESPONSE,
      ArrivalCertResponseKeys.VERSION
    )
    let submissionLogTitle = translate(
      Namespace.ARRIVAL_CERT_RESPONSE,
      ArrivalCertResponseKeys.SUBMISSION_LOG_TITLE
    )
    let customerRefNo = translate(
      Namespace.ARRIVAL_CERT_RESPONSE,
      ArrivalCertResponseKeys.CUSTOMER_REF_NO
    )
    let submissionType = translate(
      Namespace.ARRIVAL_CERT_RESPONSE,
      ArrivalCertResponseKeys.SUBMISSION_TYPE
    )
    let submissionDateTime = translate(
      Namespace.ARRIVAL_CERT_RESPONSE,
      ArrivalCertResponseKeys.SUBMISSION_DATE_TIME
    )
    let submitId = translate(
      Namespace.ARRIVAL_CERT_RESPONSE,
      ArrivalCertResponseKeys.SUBMIT_ID
    )
    let method = translate(
      Namespace.ARRIVAL_CERT_RESPONSE,
      ArrivalCertResponseKeys.METHOD
    )

    return {
      arrivalCertResponse,
      tableTitle,
      id,
      arrivalCertResponseId,
      status,
      remarks,
      responsePath,
      ccn,
      createdBy,
      createdDate,
      updatedBy,
      updatedDate,
      version,
      submissionLogTitle,
      customerRefNo,
      submissionType,
      submissionDateTime,
      submitId,
      method
    }
  }

  const columns = [
    // {
    //   field: "id",
    //   title: translatedTextsObject.id,
    //   type: "numeric",
    // },
    {
      field: "ccn",
      title: translatedTextsObject.ccn,
    },
    // {
    //   field: "arrivalCertResponseId",
    //   title: translatedTextsObject.arrivalCertResponseId,
    //   type: "numeric",
    // },
    {
      field: "status",
      title: translatedTextsObject.status,
      customLookup: () => {
        return fetchCodeMaintenanceLookup(
          constants.CodeMaintenanceType.CODE_MASTER,
          undefined,
          [{ field: 'codeType', operator: EQUAL, value: 'ACIHWY_RNS_STATUS' }],
          undefined,
          'code'
        )
      },
      cellStyle: (e, rowData) => {
        return StatusStyle(rowData.status);
      }
    },
    {
      field: "remarks",
      title: translatedTextsObject.remarks,
    },
    {
      field: "responsePath",
      title: translatedTextsObject.responsePath,
    }
    // {
    //   field: "createdBy",
    //   title: translatedTextsObject.createdBy,
    // },
    // {
    //   field: "createdDate",
    //   title: translatedTextsObject.createdDate,
    //   type: "date",
    //   filterComponent: CngDateRangeFilter,
    // },
    // {
    //   field: "updatedBy",
    //   title: translatedTextsObject.updatedBy,
    // },
    // {
    //   field: "updatedDate",
    //   title: translatedTextsObject.updatedDate,
    //   type: "date",
    //   filterComponent: CngDateRangeFilter,
    // },
    // {
    //   field: "version",
    //   title: translatedTextsObject.version,
    // }
  ]

  const submissionLogColumns = [
    {
      field: "batchId",
      title: translatedTextsObject.customerRefNo,
    },
    {
      field: "refNo",
      title: translatedTextsObject.ccn,
    },
    {
      field: "submissionType",
      title: translatedTextsObject.submissionType,
    },
    {
      field: "submittedDate",
      title: translatedTextsObject.submissionDateTime,
      type: "datetime",
      filterComponent: CngDateRangeFilter,
    },
    {
      field: "submitId",
      title: translatedTextsObject.submitId,
    },
    {
      field: "subMode",
      title: translatedTextsObject.method,
    }
  ]  

  return (
    <>
    <Card>
      {/* <CngSimpleCardHeader title={translatedTextsObject.tableTitle} /> */}
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CngCrudTable
              {...props}
              fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
              columns={columns}
              fetch={{ url: ArrivalCertResponseApiUrls.GET }}
              fetchFilters={[
                {
                  field: 'arrivalCertResponseId', operator: 'equal', value: (undefined !== id ? id : "a1b2c123")
                }
              ]}
              idAccessor={id}
              notification={notification}
              showRefreshButton={true}
              options={{
                pageSize: 10,
                pageSizeOptions: [10, 20, 50, 100, 500, 1000],
                toolbar: true
              }} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
    <Card style={{ marginTop: 16 }}>
    {<CngSimpleCardHeader title={translatedTextsObject.submissionLogTitle} /> }
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CngCrudTable
              fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
              columns={submissionLogColumns}
              fetch={{ url: AciHighwayTransactionApiUrls.GET }}
              fetchFilters={[
                {field: 'headerId', operator: 'equal', value: id},{field: 'docType', operator: 'equal', value: 'ArrCert'}
              ]}
              options={{
                pageSize: 10,
                pageSizeOptions: [10, 20, 50, 100, 500, 1000],
                toolbar: true
              }} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
    </>
  )
}

export default ArrivalCertResponse
