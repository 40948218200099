import { Box, Chip, Typography, useTheme } from '@material-ui/core'
import { components, constants, useTranslation } from 'cng-web-lib'
import moment from 'moment-timezone'
import AciHighwayStatQryApiUrls from 'src/apiUrls/AciHighwayStatusQueryApiUrls'
import AciHighwayStatQryKeys from 'src/constants/locale/key/AciHighwayStatusQuery'
import React, { useEffect, useState } from 'react'
import Namespace from 'src/constants/locale/Namespace'
import Table from '../../../components/aciacehighway/Table'
import { getStatusMetadata } from '../../../common/NACommon'
import FormProperties from './FormProperties'
import axios from 'axios'
import { FileForUserGetPartyId, FileForUserGetUserDetails } from 'src/common/FileForUserCommon'
import { FormProvider, useForm } from 'react-hook-form'
import useCustomYupValidationResolver from 'src/components/aciacehighway/hooks/useCustomYupValidationResolver'

const {
  table: { useFetchCodeMaintenanceLookup },
  button: { CngButton },
  form: { CngForm },
  CngTabs
} = components


const {
  FormState,
  filter: { BETWEEN, EQUAL, IN, LIKE },
} = constants

function TablePage(props) {
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const { showNotification } = props

  const { translate } = useTranslation(Namespace.ACIHWY_MANIFEST)
  const translatedTextsObject = makeTranslatedTextsObject()
  const { initialValues, makeValidationSchema } = FormProperties.formikProps
  const theme = useTheme()

  const methods = useForm({
    defaultValues: FormProperties.formikProps.initialValues, // Default values from FormProperties
    resolver: useCustomYupValidationResolver(makeValidationSchema(translate))
  });

  // Clear fields function
  const clearFields = () => {
    methods.reset(FormProperties.formikProps.initialValues); // Reset fields to initial values
  };
  const [statusLookup, setStatusLookup] = useState([]);
  useEffect(() => {
    Promise.all([fetchCodeMaintenanceLookup(constants.CodeMaintenanceType.CODE_MASTER,
      undefined, [{ field: 'codeType', operator: 'equal', value: 'ACIHWY_RNS_STATUS' }],
      undefined, 'code')])
      .then(([e]) => setStatusLookup(e))
  }, []);

  function makeTranslatedTextsObject() {
    let ccn = translate(
      Namespace.ACI_HIGHWAY_STAT_QRY,
      AciHighwayStatQryKeys.CCN
    )
    let entryNo = translate(
      Namespace.ACI_HIGHWAY_STAT_QRY,
      AciHighwayStatQryKeys.ENTRY_NO
    )
    let status = translate(
      Namespace.ACI_HIGHWAY_STAT_QRY,
      AciHighwayStatQryKeys.STATUS
    )
    let arrivalDateTime = translate(
      Namespace.ACI_HIGHWAY_STAT_QRY,
      AciHighwayStatQryKeys.ARRIVAL_DATE_TIME
    )
    let createDateTime = translate(
      Namespace.ACI_HIGHWAY_STAT_QRY,
      AciHighwayStatQryKeys.CREATE_DATE_TIME
    )
    let createdDateTime = translate(
      Namespace.ACI_HIGHWAY_STAT_QRY,
      AciHighwayStatQryKeys.CREATIONDATETIME
    )
    let submitDateTime = translate(
      Namespace.ACI_HIGHWAY_STAT_QRY,
      AciHighwayStatQryKeys.SUBMIT_DATE_TIME
    )
    let submitButton = translate(
      Namespace.ACI_HIGHWAY_STAT_QRY,
      AciHighwayStatQryKeys.SUBMIT_BUTTON
    )
    let submitSuccessMsg = translate(
      Namespace.ACI_HIGHWAY_STAT_QRY,
      AciHighwayStatQryKeys.SUBMIT_SUCCESS_MSG
    )

    return {
      ccn,
      entryNo,
      status,
      arrivalDateTime,
      createDateTime,
      createdDateTime,
      submitDateTime,
      submitButton,
      submitSuccessMsg
    }
  }

  const columns = [
    {
      title: `${translatedTextsObject.entryNo} / ${translatedTextsObject.ccn}`,
      sortKey: 'entryNo',
      render: (data) => (
        <Box>
          <Typography component='div' variant='inherit'>
            {data.entryNo}
          </Typography>
          <Typography color='textSecondary' style={{ fontSize: 12, lineHeight: 1.2 }}>
            {data.ccn}
          </Typography>
        </Box>
      )
    },
    {
      field: 'submitDateTime',
      sortKey: 'submitDateTime',
      title: translatedTextsObject.submitDateTime,
      render: (data) =>
        data.submitDateTime &&
        moment(data.submitDateTime).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')
    },
    {
      field: 'createDateTime',
      sortKey: 'createDateTime',
      title: translatedTextsObject.createDateTime,
      render: (data) =>
        data.createDateTime &&
        moment(data.createDateTime).tz("Canada/Eastern").format('D MMM YYYY')
    },
    {
      field: 'status',
      sortKey: 'status',
      title: translatedTextsObject.status,
      render: (data) => {
        const status = getStatusMetadata(data.status)

        return (
          <Chip
            label={getStatusDescription(data.status)}
            size='small'
            style={{
              backgroundColor: status.color,
              color: status.contrastColor,
              fontSize: 12
            }}
          />
        )
      }
    }
  ]

  const filters = [
    {
      label: translatedTextsObject.status,
      type: 'checkbox',
      name: 'status',
      operatorType: IN,
      options: [
        {
          label: 'Draft',
          value: 'DRAFT',
          filterValue: {
            value: '1005'
          }
        },
        {
          label: 'Sent',
          value: 'SENT',
          filterValue: {
            value: '1008'
          }
        },
        {
          label: 'Goods released',
          value: 'GOODS_RELEASED',
          filterValue: {
            value: '4'
          }
        },
        {
          label: 'Declaration Accepted, Awaiting arrival of goods',
          value: 'DECLARATION_ACCEPTED_AWAITING_ARRIVAL_OF_GOODS',
          filterValue: {
            value: '9'
          }
        },
        {
          label: 'Declaration Accepted, Awaiting Customs Processing',
          value: 'DECLARATION_ACCEPTED_AWAITING_CUSTOMS_PROCESSING',
          filterValue: {
            value: '34'
          }
        },
        {
          label: 'Message Content Rejected with comment',
          value: 'MESSAGE_CONTENT_REJECTED_WITH_COMMENT',
          filterValue: {
            value: '2'
          }
        },
        {
          label: 'Message Content Accepted',
          value: 'MESSAGE_CONTENT_ACCEPTED',
          filterValue: {
            value: '1'
          }
        },
        {
          label: 'Goods required for examination - referred',
          value: 'GOODS_REQUIRED_FOR_EXAMINATION_REFERRED',
          filterValue: {
            value: '5'
          }
        },
        {
          label: 'Goods May Move, Detain at Destination (CFIA)',
          value: 'GOODS_MAY_MOVE_DETAIN_AT_DESTINATION_CFIA',
          filterValue: {
            value: '8'
          }
        },
        {
          label: 'Error message',
          value: 'ERROR_MESSAGE',
          filterValue: {
            value: '14'
          }
        },
        {
          label: 'Authorised to deliver CSA Shipment',
          value: 'AUTHORISED_TO_DELIVER_CSA_SHIPMENT',
          filterValue: {
            value: '23'
          }
        },
      ]
    },
    {
      label: translatedTextsObject.submitDateTime,
      type: 'daterange',
      name: 'submitDateTime',
      operatorType: BETWEEN
    },
    {
      label: translatedTextsObject.createDateTime,
      type: 'daterange',
      name: 'createDateTime',
      operatorType: BETWEEN
    }
  ]

  function getStatusDescription(statusCode) {
    if (statusLookup[statusCode] != null) {
      return statusLookup[statusCode];
    }
    return statusCode;
  }

  function handleSubmitStatusQuery(data) {
    console.log(data);
    const instance = axios.create({
      withCredentials: false,
      headers: {
        Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('userToken'))
      }
    })

    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      data.fileForUserId = fileForUserDetails.fileForUserId;
      data.fileForUserLoginId = fileForUserDetails.fileForUserLoginId;
      data.fileForUserPartyId = fileForUserDetails.fileForUserPartyId;
    }

    instance
      .post(AciHighwayStatQryApiUrls.SUBMIT, FormProperties.toServerDataFormat(data))
      .then(
        (result) => {
          var returnData = result.data
          var errorMessages = returnData.errorMessages

          if (
            result.status == 200 &&
            returnData != null &&
            errorMessages == null
          ) {
            showNotification('success', translatedTextsObject.submitSuccessMsg)
          } else {
            showNotification('error', errorMessages)
          }
        },
        (error) => {
          console.log(error)
        }
      )
  }

  return (
    <CngTabs
      headerColor='primary'
      tabs={[
        {
          tabName: 'Submit Request',
          tabContent: (
            <CngForm
              fieldLevel='form'
              formikProps={{
                initialValues,
                makeValidationSchema,
                onSubmit: (data) => {
                  console.log(data)
                  handleSubmitStatusQuery(data)
                }
              }}
              formState={FormState.COMPLETED}
              innerForm={true}
              renderBodySection={(labelMap, shouldHideMap) => (
                <FormProvider {...methods}>
                  <FormProperties.Fields
                    showNotification={showNotification}
                    shouldHideMap={shouldHideMap}
                  />
                </FormProvider>
              )}
              renderButtonSection={() => 
                <Box display='flex' justifyContent='space-between'>
                  <CngButton color='secondary' onClick={clearFields}>
                    Clear
                  </CngButton>
                  <CngButton color='primary' onClick={methods.handleSubmit(handleSubmitStatusQuery)}>
                    {translatedTextsObject.submitButton}
                  </CngButton>
                </Box>
              }
            />
          )
        },
        {
          tabName: 'Awaiting Response',
          tabContent: (
            <Table
              columns={columns}
              compact
              fetch={{ url: AciHighwayStatQryApiUrls.GET }}
              fetchFilters={[
                { field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() }
              ]}
              filters={filters}
              search={{ field: 'entryNo', operator: 'LIKE' }}
              showNotification={showNotification}
              sortConfig={{
                 type: 'column',
                 defaultField: 'createDateTime',
                 defaultDirection: 'DESC'
              }}
            />
          )
        }
      ]}
    />
  )
}

export default TablePage
