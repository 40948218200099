import React, {useState , useEffect} from 'react'
import Namespace from 'src/constants/locale/Namespace'
import AciHwReleaseNoticeKeys from 'src/constants/locale/key/AciHwReleaseNotice'
import { Grid, Card, CardContent,Typography } from '@material-ui/core'
import {
  components,
  DataFlattener,
  DateTimeFormatter,
  useServices
} from 'cng-web-lib'
import makeValidationSchema from './MakeValidationSchema'
import { useTranslation } from 'cng-web-lib'
import ContainersFormProperties from './ContainersFormProperties'
import { useFormContext } from 'react-hook-form'

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngTextField,
      CngDateField,
      CngCodeMasterAutocompleteField
    },
  },
  table: {
    CngLocalModeDialogFormTable,
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  id: "",
  partyId: "",
  headerId: "",
  entryNumber: "",
  receivedDate: "",
  keyType: "",
  serviceOptionId: "",
  customsOfficeCode: "",
  warehouseCode: "",
  cbsaProcessingDate: "",
  releaseDate: "",
  processingInd: "",
  deliveryInst1: "",
  deliveryInst2: "",
  rejectComments1: "",
  rejectComments2: "",
  ccn: "",
  senderMsgRefNo: "",
  rejectType: "",
  appErrorCode: "",
  errorDesc: "",
  createdBy: "",
  createdDate: "",
  updatedBy: "",
  updatedDate: "",
  version: "",
  containers: [],
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.ACI_HW_RELEASE_NOTICE)
  const translatedTextsObject = makeTranslatedTextsObject()
  const { watch } = useFormContext()
  const {securedSendRequest } = useServices()

  const [errorDesc, setErrorDesc] = useState("");
  const appErrorCode = watch('appErrorCode')

  function fetchErrorDescription(errorCode){
    try {
      securedSendRequest.execute(
        'POST',
        `${process.env.REACT_APP_USER_ACIHW_URL}/aci-highway/response-errorcode/getErrorDesc?errorCode=${encodeURIComponent(errorCode)}`,
        {},
        (response) => {
          if (response.status === 200) {
            if (response.data) {
              const errorMessages = response.data.errorMessages
              if (!errorMessages) {
                setErrorDesc(response.data.errorDesc); 
              } 
            }
          }
        },
      )
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (appErrorCode) {
      fetchErrorDescription(appErrorCode);
    }
  }, [appErrorCode]);


  function makeTranslatedTextsObject() {
    let aciHwReleaseNotice = translate(
      Namespace.ACI_HW_RELEASE_NOTICE,
      AciHwReleaseNoticeKeys.TITLE
    )
    let id = translate(
      Namespace.ACI_HW_RELEASE_NOTICE,
      AciHwReleaseNoticeKeys.ID
    )
    let partyId = translate(
      Namespace.ACI_HW_RELEASE_NOTICE,
      AciHwReleaseNoticeKeys.PARTY_ID
    )
    let headerId = translate(
      Namespace.ACI_HW_RELEASE_NOTICE,
      AciHwReleaseNoticeKeys.HEADER_ID
    )
    let entryNumber = translate(
      Namespace.ACI_HW_RELEASE_NOTICE,
      AciHwReleaseNoticeKeys.ENTRY_NUMBER
    )
    let receivedDate = translate(
      Namespace.ACI_HW_RELEASE_NOTICE,
      AciHwReleaseNoticeKeys.RECEIVED_DATE
    )
    let keyType = translate(
      Namespace.ACI_HW_RELEASE_NOTICE,
      AciHwReleaseNoticeKeys.KEY_TYPE
    )
    let serviceOptionId = translate(
      Namespace.ACI_HW_RELEASE_NOTICE,
      AciHwReleaseNoticeKeys.SERVICE_OPTION_ID
    )
    let customsOfficeCode = translate(
      Namespace.ACI_HW_RELEASE_NOTICE,
      AciHwReleaseNoticeKeys.CUSTOMS_OFFICE_CODE
    )
    let warehouseCode = translate(
      Namespace.ACI_HW_RELEASE_NOTICE,
      AciHwReleaseNoticeKeys.WAREHOUSE_CODE
    )
    let cbsaProcessingDate = translate(
      Namespace.ACI_HW_RELEASE_NOTICE,
      AciHwReleaseNoticeKeys.CBSA_PROCESSING_DATE
    )
    let releaseDate = translate(
      Namespace.ACI_HW_RELEASE_NOTICE,
      AciHwReleaseNoticeKeys.RELEASE_DATE
    )
    let processingInd = translate(
      Namespace.ACI_HW_RELEASE_NOTICE,
      AciHwReleaseNoticeKeys.PROCESSING_IND
    )
    let deliveryInst1 = translate(
      Namespace.ACI_HW_RELEASE_NOTICE,
      AciHwReleaseNoticeKeys.DELIVERY_INST1
    )
    let deliveryInst2 = translate(
      Namespace.ACI_HW_RELEASE_NOTICE,
      AciHwReleaseNoticeKeys.DELIVERY_INST2
    )
    let rejectComments1 = translate(
      Namespace.ACI_HW_RELEASE_NOTICE,
      AciHwReleaseNoticeKeys.REJECT_COMMENTS1
    )
    let rejectComments2 = translate(
      Namespace.ACI_HW_RELEASE_NOTICE,
      AciHwReleaseNoticeKeys.REJECT_COMMENTS2
    )
    let ccn = translate(
      Namespace.ACI_HW_RELEASE_NOTICE,
      AciHwReleaseNoticeKeys.CCN
    )
    let senderMsgRefNo = translate(
      Namespace.ACI_HW_RELEASE_NOTICE,
      AciHwReleaseNoticeKeys.SENDER_MSG_REF_NO
    )
    let rejectType = translate(
      Namespace.ACI_HW_RELEASE_NOTICE,
      AciHwReleaseNoticeKeys.REJECT_TYPE
    )
    let appErrorCode = translate(
      Namespace.ACI_HW_RELEASE_NOTICE,
      AciHwReleaseNoticeKeys.APP_ERROR_CODE
    )
    let errorDesc = translate(
      Namespace.ACI_HW_RELEASE_NOTICE,
      AciHwReleaseNoticeKeys.ERROR_DESC
    )
    let createdBy = translate(
      Namespace.ACI_HW_RELEASE_NOTICE,
      AciHwReleaseNoticeKeys.CREATED_BY
    )
    let createdDate = translate(
      Namespace.ACI_HW_RELEASE_NOTICE,
      AciHwReleaseNoticeKeys.CREATED_DATE
    )
    let updatedBy = translate(
      Namespace.ACI_HW_RELEASE_NOTICE,
      AciHwReleaseNoticeKeys.UPDATED_BY
    )
    let updatedDate = translate(
      Namespace.ACI_HW_RELEASE_NOTICE,
      AciHwReleaseNoticeKeys.UPDATED_DATE
    )
    let version = translate(
      Namespace.ACI_HW_RELEASE_NOTICE,
      AciHwReleaseNoticeKeys.VERSION
    )
    let containers = translate(
      Namespace.ACI_HW_RELEASE_NOTICE,
      AciHwReleaseNoticeKeys.Containers.TITLE
    )
    let containerNumber = translate(
      Namespace.ACI_HW_RELEASE_NOTICE,
      AciHwReleaseNoticeKeys.Containers.CONTAINER_NUMBER
    )

    return {
      aciHwReleaseNotice,
      id,
      partyId,
      headerId,
      entryNumber,
      receivedDate,
      keyType,
      serviceOptionId,
      customsOfficeCode,
      warehouseCode,
      cbsaProcessingDate,
      releaseDate,
      processingInd,
      deliveryInst1,
      deliveryInst2,
      rejectComments1,
      rejectComments2,
      ccn,
      senderMsgRefNo,
      rejectType,
      appErrorCode,
      errorDesc,
      createdBy,
      createdDate,
      updatedBy,
      updatedDate,
      version,
      containers,
      containerNumber
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card>
          <CngSimpleCardHeader title={translatedTextsObject.aciHwReleaseNotice} />
          <CardContent>

            <Grid container spacing={3}>
              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngTextField
                  name="id"
                  label={translatedTextsObject.id}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngTextField
                  name="partyId"
                  label={translatedTextsObject.partyId}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngTextField
                  name="headerId"
                  label={translatedTextsObject.headerId}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.serviceOptionId}>
                <CngTextField
                  name="serviceOptionId"
                  label={translatedTextsObject.serviceOptionId}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.entryNumber}>
                <CngTextField
                  name="entryNumber"
                  label={translatedTextsObject.entryNumber}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.ccn}>
                <CngTextField
                  name="ccn"
                  label={translatedTextsObject.ccn}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.receivedDate}>
                <CngDateField
                  name="receivedDate"
                  label={translatedTextsObject.receivedDate}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngTextField
                  name="keyType"
                  label={translatedTextsObject.keyType}
                  disabled={disabled}
                />
              </CngGridItem>
              
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.customsOfficeCode}>
                <CngTextField
                  name="customsOfficeCode"
                  label={translatedTextsObject.customsOfficeCode}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.warehouseCode}>
                <CngTextField
                  name="warehouseCode"
                  label={translatedTextsObject.warehouseCode}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngDateField
                  name="cbsaProcessingDate"
                  label={translatedTextsObject.cbsaProcessingDate}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.releaseDate}>
                <CngDateField
                  name="releaseDate"
                  label={translatedTextsObject.releaseDate}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.processingInd}>
                <CngCodeMasterAutocompleteField
                  name="processingInd"
                  label={translatedTextsObject.processingInd}
                  disabled={disabled}
                  codeType='ACIHWY_RNS_STATUS'
                />
              </CngGridItem>
              <Grid item xs={12} >
              <Typography style={{ fontWeight: 600 }}>
                    Reject Reasons
              </Typography>
              </Grid>
              <Grid item xs={12} container spacing={3}>
              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngTextField
                  name="deliveryInst1"
                  label={translatedTextsObject.deliveryInst1}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngTextField
                  name="deliveryInst2"
                  label={translatedTextsObject.deliveryInst2}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngTextField
                  name="rejectType"
                  label={translatedTextsObject.rejectType}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngTextField
                  name="rejectComments1"
                  label={translatedTextsObject.rejectComments1}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngTextField
                  name="rejectComments2"
                  label={translatedTextsObject.rejectComments2}
                  disabled={disabled}
                />
              </CngGridItem>
              
              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngTextField
                  name="senderMsgRefNo"
                  label={translatedTextsObject.senderMsgRefNo}
                  disabled={disabled}
                />
              </CngGridItem>
              
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.appErrorCode}>
                <CngTextField
                  name="appErrorCode"
                  label={translatedTextsObject.appErrorCode}
                  disabled={disabled}
                />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.errorDesc}>
                <CngTextField
                  name="errorDesc"
                  label={translatedTextsObject.errorDesc}
                  disabled={disabled}
                  value={errorDesc} 
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngTextField
                  name="createdBy"
                  label={translatedTextsObject.createdBy}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngDateField
                  name="createdDate"
                  label={translatedTextsObject.createdDate}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngTextField
                  name="updatedBy"
                  label={translatedTextsObject.updatedBy}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngDateField
                  name="updatedDate"
                  label={translatedTextsObject.updatedDate}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngTextField
                  name="version"
                  label={translatedTextsObject.version}
                  disabled={disabled}
                />
              </CngGridItem>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CngSimpleCardHeader title={translatedTextsObject.containers} />
          <CardContent>
            <CngLocalModeDialogFormTable
              fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
              disabled={disabled}
              showNotification={showNotification}
              propertyName="containers"
              tableColumns={[
                {
                  title: translatedTextsObject.containerNumber,
                  field: "containerNumber",
                },
              ]}
              formProperties={ContainersFormProperties}
              initialData={DEFAULT_INITIAL_VALUES.containers}
              isViewOnly={disabled}
            //tableConfigurationCode="REPLACE_WITH_CORE_TABLE_CONFIG_CODE"
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

function toClientDataFormat(serverData) {
  let localData = DataFlattener.parse(serverData);
  localData.releaseDate = DateTimeFormatter.toClientDate(
    localData.releaseDate
  );
  localData.receivedDate = DateTimeFormatter.toClientDate(
    localData.receivedDate
  );
  localData.cbsaProcessingDate = DateTimeFormatter.toClientDate(
    localData.cbsaProcessingDate
  );
  localData.createdDate = DateTimeFormatter.toClientDate(
    localData.createdDate
  );
  localData.updatedDate = DateTimeFormatter.toClientDate(
    localData.updatedDate
  );
  return localData;
}

function toServerDataFormat(localData) {
  localData.releaseDate = DateTimeFormatter.toServerDate(
    localData.releaseDate
  );
  localData.receivedDate = DateTimeFormatter.toServerDate(
    localData.receivedDate
  );
  localData.cbsaProcessingDate = DateTimeFormatter.toServerDate(
    localData.cbsaProcessingDate
  );
  localData.createdDate = DateTimeFormatter.toServerDate(
    localData.createdDate
  );
  localData.updatedDate = DateTimeFormatter.toServerDate(
    localData.updatedDate
  );
  return DataFlattener.unflatten(localData);
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
