import React, { useEffect } from 'react'
import Namespace from 'src/constants/locale/Namespace'
import AciHighwayStatQryKeys from 'src/constants/locale/key/AciHighwayStatusQuery'
import { Grid, Typography } from '@material-ui/core'
import { components, DataFlattener, } from 'cng-web-lib'
import makeValidationSchema from './MakeValidationSchema'
import { NaSublocationAutocompleteField } from 'src/components/na'
import { useTranslation } from 'cng-web-lib'
import { useFormContext, useWatch } from "react-hook-form";
import { FileForUserGetUserDetails } from 'src/common/FileForUserCommon'
import CngSection from '../../../components/cngcomponents/CngSection'

const {
  form: { field: { CngTextField } },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  id: "",
  ccn: "",
  entryNo: "",
  warehouseCode: "",
  partyId: "",
  messageType: "",
  status: "",
  version: "",
  oriCreatedBy: "",
  oriUpdatedBy: "",
  oriSubmittedBy: "",
  apiAutoSubmit: null
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap }) {
  const { setValue } = useFormContext();
  const { translate } = useTranslation(Namespace.ACI_HIGHWAY_STAT_QRY)
  const translatedTextsObject = makeTranslatedTextsObject()

  useWatch("fileForUserId");
  useWatch("fileForUserLoginId")
  useWatch("fileForUserPartyId");
  useEffect(() => {
    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      setValue("fileForUserId", fileForUserDetails.fileForUserId)
      setValue("fileForUserLoginId", fileForUserDetails.fileForUserLoginId)
      setValue("fileForUserPartyId", fileForUserDetails.fileForUserPartyId)
    }
  }, []);

  function makeTranslatedTextsObject() {
    let aciHighway = translate(
      Namespace.ACI_HIGHWAY_STAT_QRY,
      AciHighwayStatQryKeys.TITLE
    )
    let ccn = translate(
      Namespace.ACI_HIGHWAY_STAT_QRY,
      AciHighwayStatQryKeys.CCN
    )
    let entryNo = translate(
      Namespace.ACI_HIGHWAY_STAT_QRY,
      AciHighwayStatQryKeys.ENTRY_NO
    )
    let warehouseCode = translate(
      Namespace.ACI_HIGHWAY_STAT_QRY,
      AciHighwayStatQryKeys.WAREHOUSE_CODE
    )
    let status = translate(
      Namespace.ACI_HIGHWAY_STAT_QRY,
      AciHighwayStatQryKeys.STATUS
    )
    let arrivalDateTime = translate(
      Namespace.ACI_HIGHWAY_STAT_QRY,
      AciHighwayStatQryKeys.ARRIVAL_DATE_TIME
    )
    let createDateTime = translate(
      Namespace.ACI_HIGHWAY_STAT_QRY,
      AciHighwayStatQryKeys.CREATE_DATE_TIME
    )
    let submitDateTime = translate(
      Namespace.ACI_HIGHWAY_STAT_QRY,
      AciHighwayStatQryKeys.SUBMIT_DATE_TIME
    )
    let version = translate(
      Namespace.ACI_HIGHWAY_STAT_QRY,
      AciHighwayStatQryKeys.VERSION
    )

    return {
      aciHighway,
      ccn,
      entryNo,
      warehouseCode,
      status,
      arrivalDateTime,
      createDateTime,
      submitDateTime,
      version
    }
  }

  const mandatoryLabel = (label) => (
    <>
      {label}
      <Typography variant='inherit' color='error'> **</Typography>
    </>
  )


  return (
    <CngSection
      title='Request'
      subheader={
        <Typography color='textSecondary' variant='caption'>
          <Typography variant='inherit' color='error'>** </Typography>
          Denotes only one of the field is required.
        </Typography>
      }
    >
      <Grid container spacing={1}>
        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.ccn}>
          <CngTextField
            name="ccn"
            label={mandatoryLabel(translatedTextsObject.ccn)}
            disabled={disabled}
            onBlur={(e) => { setValue("ccn", e.target.value.toUpperCase(), {
              shouldValidate: true
            }) }}
            size='small'
            inputProps={{ style: { textTransform: 'uppercase' }, maxLength: 25 }}
          />
        </CngGridItem>
        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.entryNo}>
          <CngTextField
            name="entryNo"
            label={mandatoryLabel(translatedTextsObject.entryNo)}
            disabled={disabled}
            onBlur={(e) => { setValue("entryNo", e.target.value.toUpperCase(), {
              shouldValidate: true
            }) }}
            size='small'
            inputProps={{ style: { textTransform: 'uppercase' }, maxLength: 14 }}
          />
        </CngGridItem>
        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.warehouseCode}>
          <NaSublocationAutocompleteField
            name="warehouseCode"
            label={translatedTextsObject.warehouseCode}
            disabled={disabled}
            size='small'
          />
        </CngGridItem>
      </Grid>
    </CngSection>
  )
}

function toClientDataFormat(serverData) {
  let localData = DataFlattener.parse(serverData);
  return localData;
}

function toServerDataFormat(localData) {
  return DataFlattener.unflatten(localData);
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
